import {
    AUTHENTICATE,
    AUTHENTICATE_ERROR,
    SIGN_IN,
    SIGN_IN_ERROR,
    SIGN_OUT,
    // SIGN_OUT_ERROR,
    CREATE_FILM,
    CREATE_FILM_ERROR,
    GET_FILMS,
    GET_FILMS_ERROR,
    GET_FILM,
    GET_FILM_ERROR,
    EDIT_FILM,
    EDIT_FILM_ERROR,
    DELETE_FILM,
    DELETE_FILM_ERROR,

    CREATE_ABOUT,
    CREATE_ABOUT_ERROR,
    GET_ABOUT,
    GET_ABOUT_ERROR,
    EDIT_ABOUT,
    EDIT_ABOUT_ERROR,

    FILL_MODAL,
    FILM_UPDATING

} from './types';

import { authHeader } from '../util/auth-header';
import { accessValid, refreshValid, getRefreshToken } from '../util/tokenHandler';

import films from '../apis/films';
import token from '../apis/token';
import history from '../history';


export const authenticate = path => async dispatch => {
    if(accessValid()) {
        dispatch({type: AUTHENTICATE, payload: {isSignedIn: true}});
        if(path === '/login') {
            history.push('/admin');
        }

    } else {
        if(refreshValid()) {
            let refreshToken = getRefreshToken();
            
            try {
                const response = await token.post('/refresh', {  refresh: refreshToken });

                let payload = { refresh: refreshToken, ...response.data }
                localStorage.setItem('user', JSON.stringify(payload));
                dispatch({type: AUTHENTICATE, payload: {isSignedIn: true}});
                if(path === '/login') {
                    history.push('/admin');
                }

            } catch(error) {
                console.dir(error);
                dispatch({type: AUTHENTICATE_ERROR, payload: error});
            }

        } else {
            dispatch({type: AUTHENTICATE, payload: {isSignedIn: false}});
            localStorage.removeItem('user');
            history.push('/login');
        }
    }
};


export  const signIn = (credentials, path) => async dispatch => {
    try {
        const response = await token.post('', credentials);
        let payload = {...response.data};

        localStorage.setItem('user', JSON.stringify(payload));

        dispatch({ type: SIGN_IN, payload });
        dispatch({type: SIGN_IN_ERROR, payload: null});
        history.push(path);

    } catch(error) {
        dispatch({type: SIGN_IN_ERROR, payload: error});
    }
};


export const signOut = () => {
    localStorage.removeItem('user');
    history.push('/login');
    return {
        type: SIGN_OUT
    }
};


export const fillModal = modal => {
    return {
        type: FILL_MODAL,
        payload: modal
    }
};


export const createFilm = formValues => async (dispatch) => {
    dispatch(authenticate('', dispatch));

    try {
        const response = await films.post('films/', formValues, authHeader());
        dispatch({type: CREATE_FILM, payload: response.data});
        dispatch({type: CREATE_FILM_ERROR, payload: null});
        dispatch({type: FILM_UPDATING, payload: false});
        history.push('/admin');

    } catch(error) {
        dispatch({type: CREATE_FILM_ERROR, payload: error});
    }
};


export const getFilms = () => async dispatch => {
    try {
        const response = await films.get('/films/');
        dispatch({type: GET_FILMS, payload: response.data});
        dispatch({type: GET_FILMS_ERROR, payload: null});

    } catch(error) {
        dispatch({type: GET_FILMS_ERROR, payload: error});
    }
};


export const getFilm = filmId => async dispatch => {
    try {
        const response = await films.get(`/films/${filmId}/`);
        dispatch({type: GET_FILM, payload: response.data});
        dispatch({type: GET_FILM_ERROR, payload: null});
        
    } catch(error) {
        dispatch({type: GET_FILM_ERROR, payload: error});
    }
};


export const editFilm = (formValues, filmId) => async dispatch => {
    dispatch(authenticate('', dispatch));

    try {
        const response = await films.patch(`/films/${filmId}/`, formValues, authHeader());
        dispatch({type: EDIT_FILM, payload: response.data});
        dispatch({type: EDIT_FILM_ERROR, payload: null});
        dispatch({type: FILM_UPDATING, payload: false});

    } catch(error) {
        dispatch({type: EDIT_FILM_ERROR, payload: error});
    }
};


export const deleteFilm = filmId => async (dispatch, getState) => {
    dispatch(authenticate('', dispatch));

    const film = getState().films[filmId];
    try {
        await films.delete(`/films/${filmId}/`, authHeader());
        dispatch({type: DELETE_FILM, payload: {id: filmId, title: film.title}});
        dispatch({type: DELETE_FILM_ERROR, payload: null});

    } catch(error) {
        dispatch({type: DELETE_FILM_ERROR, payload: error});
    }
};


export const createAbout = formValues => async dispatch => {
    dispatch(authenticate('', dispatch));

    try {
        const response = await films.post('/aboutme/', formValues, authHeader());
        dispatch({type: CREATE_ABOUT, payload: response.data});
        dispatch({type: CREATE_ABOUT_ERROR, payload: null});

    } catch(error) {
        dispatch({type: CREATE_ABOUT_ERROR, payload: error});
    }
};


export const getAbout = () => async dispatch => {
    try {
        const response = await films.get('/aboutme/');
        dispatch({type: GET_ABOUT, payload: response.data});
        dispatch({type: GET_ABOUT_ERROR, payload: null});

    } catch(error) {
        dispatch({type: GET_ABOUT_ERROR, payload: error});
    }
};


export const editAbout = formValues => async dispatch => {
    dispatch(authenticate('', dispatch));
    
    try {
        const response = await films.put('/aboutme/1/', formValues, authHeader());
        dispatch({type: EDIT_ABOUT, payload: response.data});
        dispatch({type: EDIT_ABOUT_ERROR, payload: null});

    } catch(error) {
        dispatch({type: EDIT_ABOUT_ERROR, payload: error});
    }
};

export const startFilmUpdate = () => {
    return {
        type: FILM_UPDATING,
        payload: true
    }
};
