import React from 'react';

import ProgressiveImage from 'react-progressive-image';

import M from 'materialize-css';

import './Photo.css';


class Photo extends React.Component {
    componentDidMount() {
        M.Materialbox.init(this.imageRef);
    }

    render() {
        const { photo } = this.props;
        return (
            <div className="col s12 m6 l4 photo">
                <ProgressiveImage 
                    // delay={1000}
                    src={photo.small}
                    srcSetData={{
                        srcSet: `${photo.small}, ${photo.medium} 1.3x, ${photo.large} 1.9x, ${photo.xlarge} 2.7x`
                    }}
                    placeholder={photo.thumbnail}
                >
                    {(src, loading, srcSetData) => (
                        <img 
                            // style={{ filter: loading ? 'blur(10px)' : 'blur(0)' }}
                            src={src}
                            srcSet={srcSetData.srcSet}
                            alt={photo.title}
                            ref={elem => this.imageRef = elem}
                            className="responsive-img materialboxed"
                        />
                    )}
                </ProgressiveImage>
            </div>
        );
    }
}

export default Photo;
