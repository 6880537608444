import React from 'react';


class AboutInput extends React.Component {
    render() {
        const { input, title, meta } = this.props;
        return (
            <tr>
                <td>{title}</td>
                <td>
                    <input 
                        type="text" {...input} 
                        autoComplete="off"
                        placeholder="Haz click para agregar"
                    />
                    <span className="helper-text" data-error={meta.error}></span>
                </td>
            </tr>
        );
    }
}

export default AboutInput;
