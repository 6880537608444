import React from 'react';
import { connect } from 'react-redux';
import {Route, Switch } from 'react-router-dom';

import { authenticate } from '../actions';

import NavBar from './NavBar'; 
import Admin from './admin/Admin';
import FilmCreate from './admin/film/FilmCreate';
import FilmUpdate from './admin/film/FilmUpdate';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class AdminLayout extends React.Component {
    componentDidMount() {
        this.props.authenticate();
    }

    componentDidUpdate(prevProps) {
        this.props.authenticate();
        const { notification } = this.props;

        if (notification && prevProps.notification !== notification) {
            this.displayToastMessage();
        }
    }

    displayToastMessage() {
        const { status, message } = this.props.notification;

        switch(status) {
            case 'success':
                toast.success(message);
                break;

            case 'failure':
                for (let [key, value] of Object.entries(message)) {
                    toast.error(`${key}: ${value}`);
                }
                break;

            case 'warning':
                toast.warn(message);
                break;

            default:
                break;
        }
    }

    render() {
        const home = { path: "/films", name: "Eliezer Espinosa" };
        const links = [
            { path: "/admin", name: "Admin" },
            { path: "/logout", name: "Cerrar sesion" }
        ];

        return (
            <div>
                <header>
                    <NavBar home={home} links={links} />
                </header>    
    
                <main>
                    <Switch>
                        <Route exact path="/admin" component={Admin}/>
                        <Route path={`/admin/new`} component={FilmCreate}/>
                        <Route path={`/admin/:id/edit`} component={FilmUpdate}/>
                    </Switch>                
                </main>
    
                <footer></footer>   
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />   
            </div>
        );
    }   
}

const mapStateToProps = state => {
    return {
        notification: state.notification
    }
};

export default connect(mapStateToProps, { authenticate })(AdminLayout);
