import React from 'react';
import { connect } from 'react-redux';
import { getFilms } from '../../../actions';
import { getFilms as mapStateToProps } from '../../../selectors';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './FilmList.css';

import Loader from '../../widgets/Loader';
import FilmItem from './FilmItem';

class FilmList extends React.Component {
    componentDidMount() {
        this.props.getFilms();
    }

    renderFilms() {
        const films = this.props.films.map(
            film => 
            <CSSTransition
                key={film.id}
                timeout={500}
                classNames="film"
            >
                <FilmItem film={film} />
            </CSSTransition>
        );

        return (
            <ul className="collection films-admin">
                <TransitionGroup>
                    {films}
                </TransitionGroup>
            </ul>
        );
    }

    renderContent() {
        const { error, films } = this.props;

        if(films) {
            if(films.length) {
                return this.renderFilms();
            }

            return <div>No hay filmes que mostrar. Puede agregar uno para empezar a editar</div>;
        }

        if(error) {
            return <span>Hay errores: {error.message}</span>;
        }

        return <Loader/>;
    }

    render() {
        return(
            <div className="admin-film-list card-panel hoverable">
                {this.renderContent()}
            </div>
        );
    }
}

export default connect(mapStateToProps, { getFilms })(FilmList);
