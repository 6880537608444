import React from 'react';

import { connect } from 'react-redux';

import { signOut } from '../../actions';
import  Loader from '../widgets/Loader';


class Logout extends React.Component {
    componentDidMount() {
        this.props.signOut();
    }

    render() {
        return <Loader />
    }
}


export default connect(null, { signOut })(Logout);
