import React from 'react';
import { Field, reduxForm, change, untouch, submit } from 'redux-form';
import { connect } from 'react-redux';

import { fillModal, getAbout, editAbout, createAbout } from '../../../actions';
import { getAbout as mapStateToProps } from '../../../selectors';

import AboutInput from './AboutInput';
import AboutTextArea from './AboutTextArea';
import PhotoInput from './PhotoInput';

import './AboutForm.css';


class AboutForm extends React.Component {
    componentDidMount() {
        this.props.getAbout();
    }

    onSave = () => {
        this.props.fillModal({
            title: 'Modificar Contacto',
            description: 'Estas seguro que quieres guardar las modificaciones?',
            function: () => this.props.dispatch(submit('aboutForm'))
        });
    }

    onCancel = () => {
        let { initialValues } = this.props;
        Object.keys(initialValues).forEach(key => {
            this.props.dispatch(change('aboutForm', key, initialValues[key]));
            this.props.dispatch(untouch('aboutForm', key));
        });
    }

    hasChanged() {
        let { initialValues, currentValues } = this.props;

        for(var field in currentValues) {
            if(currentValues[field] !== initialValues[field]) {
                return true;
            }
        }
        return false;
    }

    renderButtons() {
        if(this.hasChanged()) {
            return (
                <div className="action-buttons">
                     <button className="btn modal-trigger" data-target="modal" onClick={this.onSave}>
                        Guardar
                    </button>
                    <button className="btn-flat" onClick={this.onCancel} type="button">
                        Cancelar
                    </button>
                </div>
            );
        }
    }

    renderPhotoInput() {
        const { thumbnail, small, medium, large, xlarge } = this.props.initialValues;
        if(thumbnail !== undefined) {
            const photoProps = { thumbnail, small, medium, large, xlarge };
            return (
                <div className="about-profile">
                    <Field name="photo" component={PhotoInput} {...photoProps}/>
                    <hr/>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="admin-about card-panel hoverable center-align">
                <div className="title">
                    <h5>Contacto</h5>
                    <hr/>
                </div>
                <div className="content">
                    { this.renderPhotoInput() }
                    <form onSubmit={() => this.props.handleSubmit(this.onSubmit)}>
                        <h6>Descripcion</h6>
                        <Field name="description" component={AboutTextArea} title="Descripcion"/>
                        <table className="highlight contacts">
                            <tbody>
                                <Field name="phone" component={AboutInput} title="Telefono"/>
                                <Field name="email" component={AboutInput} title="Correo"/>
                                <Field name="fb" component={AboutInput} title="Facebook"/>
                                <Field name="instagram" component={AboutInput} title="Instagram"/>
                                <Field name="youtube" component={AboutInput} title="Youtube"/>
                            </tbody>
                        </table>
                        {this.renderButtons()}
                    </form>
                </div>
            </div>
        );
    }
}

const validate = formValues => {
    const errors = {};
    return errors;
};

const onSubmit = (values, dispatch, props) => {
    let form = setFormData(props.initialValues, values);
    if(props.isNew) {
        createAbout(form)(dispatch);
    } else {
        editAbout(form)(dispatch);
    }
};

const setFormData = (oldValues, newValues) => {
    const data = new FormData();

    for(var key in newValues) {
        let value = newValues[key];

        if(value || value !== oldValues[key]) {
            value = value? value: '';
            data.append(key, value);
        }
    }

    return data;
}

export default connect(mapStateToProps, { fillModal, getAbout, editAbout, createAbout })(
    reduxForm({ form: 'aboutForm', enableReinitialize: true, validate, onSubmit })(AboutForm)
);
