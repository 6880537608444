import React from 'react';

import { Router, Route, Switch, Redirect } from 'react-router-dom';

import './App.css';
import history from '../history';
import { PrivateRoute } from './PrivateRoute';

import AdminLayout from './AdminLayout';
import FilmLayout from './FilmLayout';
import AboutLayout from './AboutLayout';
import LogIn from './login/Login';
import Logout from './login/Logout';


const App = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route path="/films">
                    <FilmLayout />
                </Route>
                <Route path="/about">
                    <AboutLayout />
                </Route>

                <PrivateRoute path="/admin" component={AdminLayout}/>
                <Route path="/login" component={LogIn}/>
                <Route path="/logout" component={Logout}/>
                <Redirect to="/films"/>
            </Switch>
        </Router>
    );
};

export default App;
