import React from 'react';

import { Link, NavLink } from 'react-router-dom';

import M from 'materialize-css';

import './NavBar.css';


class NavBar extends React.Component {
    componentDidMount() {
        M.Sidenav.init(this.sideNav);
    }

    renderLinks() {
        return this.props.links.map(
            link => 
                <li key={link.path}>
                    <NavLink exact activeClassName="selected" to={link.path}>
                        <span>{link.name}</span>
                    </NavLink>
                </li> 
        );
    }

    render() {
        const { path, name } = this.props.home;
        return (
            <div>
                <div className="navbar-fixed">
                    <nav>
                        <div className="nav-wrapper container">
                        <Link to={path}>
                            <span className="brand-logo">{name}</span>
                        </Link>
                        <a href="/" data-target="mobile-demo" className="sidenav-trigger">
                            <i className="material-icons">menu</i>
                        </a>
                        <ul className="right hide-on-med-and-down">
                            {this.renderLinks()}
                        </ul>
                        </div>
                    </nav>
                </div>

                <ul ref={elem => this.sideNav = elem} className="sidenav" id="mobile-demo">
                    {this.renderLinks()}
                </ul>
            </div>
        );
    }
}

export default NavBar;
