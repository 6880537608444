import React from 'react';

import { validClass } from '../../../../util/validClass';
import { ImageRatioCrop } from '../../../widgets/ImageRatioCrop';
import CancelImage from '../../../widgets/CancelImage';
import { fileToImage, reduceImageSize } from '../../../widgets/ImageRatioCrop/imageHandler';

import './PhotoInput.css';


class PhotoInput extends React.Component {
    state = { image: null, selected: false, accepted: false };

    onAccept = async () => {
        try {
            const file = await this.ResizedImage();
            const image = await fileToImage(file);
            const resizedFile = await reduceImageSize(image.image, 2048, 2048, file.type, file.name);
            const resizedImage = await fileToImage(resizedFile);
            this.setState({ image: resizedImage, accepted: true }, this.setOnChange);

        } catch(error) {
            console.log(error)
        }
    }

    onCancel = () => {
        this.setState(
            { image: null, selected: false, accepted: false },
            this.setOnChange
        );
    }

    onChange = (event) => {
        const file = event.target.files[0];
        const image = fileToImage(file);
        image.then(
            image => this.setState({ image, selected: true }),
            error => console.log(error)
        );
    }

    setOnChange() {
        const { onChange } = this.props.input;
        const { image } = this.state;
        const file = image? image.file: null;
        onChange(file);
    }

    renderAcceptedImage() {
        const { image } = this.state;
        return (
            <CancelImage onCancel={this.onCancel}>
                <img className="responsive-img" src={image.image} alt=""/>
            </CancelImage>
        );
    }

    renderSelectedImage() {
        const { image } = this.state;
        if(!image) return;

        return (
            <div className="selected-container">
                <ImageRatioCrop
                    sizerRatio={800 / 1920}
                    onAccept={e=> this.ResizedImage = e}
                    imageType={image.file.type}
                    imageName={image.file.name}
                >
                    <img className="responsive-img" src={ image.image } alt=""/>
                </ImageRatioCrop>
                <div className="selected-controls right-align">
                    <button className="btn" type="button" onClick={this.onAccept}>Accept</button>
                    <button className="btn-flat" type="button" onClick={this.onCancel}>Cancel</button>
                </div>
            </div>
        );
    }

    renderError(meta) {
        if(validClass(meta) === 'invalid') {
            return (
                <span className="error-text">{meta.error}</span>
            );
        }
    }

    renderAddButton() {
        return (
            <label className="btn">
                Agregar
                <input 
                    type="file"
                    accept="image/*" 
                    onChange={this.onChange}
                    style={{display: 'none'}}
                />
            </label>
        );
    }

    renderContent() {
        const { selected, accepted } = this.state;
        const { meta } = this.props;

        if(!selected) {
            return (
                <React.Fragment>
                    { this.renderAddButton() }
                    { this.renderError(meta) }
                </React.Fragment>
            );
        }

        if(!accepted) return this.renderSelectedImage();

        return this.renderAcceptedImage();
    }

    render() {
        return (
            <div className="center-align col s12">
                { this.renderContent() }
            </div>
        );
    }
}

export default PhotoInput;
