import React from 'react';

import { validClass } from '../../../../util/validClass';


class TextInput extends React.Component {
    render() {
        const { input, label, title, meta } = this.props;
        return (
            <div className="input-field col s6">
                <input id={label} type="text" {...input} className={validClass(meta)} autoComplete="off"/>
                <label htmlFor={label}>{title}</label>
                <span className="helper-text" data-error={meta.error}></span>
            </div>
        );
    }
}

export default TextInput;
