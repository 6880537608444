import React from 'react';

import './Film.css';

import FilmList from './FilmList';
import FilmTitle from './FilmTitle';


class Film extends React.Component {
    render() {
        return(
            <div className="admin-film">   
                <FilmTitle />
                <FilmList />
            </div>
        );
    }
}

export default Film;
