import React from 'react';

import { Link } from 'react-router-dom';
import { secondsToMinutes } from '../../util/timeHandler';

import './FilmItem.css';

import ProgressiveImage from 'react-progressive-image';


class FilmItem extends React.Component {
    state = {showTitle: null};

    onMouseEnter = () => {
        this.setState({showTitle: true});
    }

    onMouseLeave = () => {
        this.setState({showTitle: false});
    }

    getShowState = () => {
        const show = this.state.showTitle;
        if (show !== null) {
            return show? 'show-title': 'hide-title';
        }

        return 'initial-title';
    }

    renderImage() {
        const { cover } = this.props;
        if(cover) {
            return (
                <ProgressiveImage 
                        delay={500}
                        src={cover.small} 
                        srcSetData={{
                            srcSet: `${cover.small}, ${cover.medium} 1.3x, ${cover.large} 1.9x, ${cover.xlarge} 2.7x`
                        }}
                        placeholder={cover.thumbnail}
                    >
                        {(src, loading, srcSetData) => (
                            <img 
                                style={{ filter: loading ? 'blur(10px)' : 'blur(0)' }}
                                src={src}
                                srcSet={srcSetData.srcSet}
                                alt={this.props.title}
                                className="responsive-img"
                            />
                        )}
                    </ProgressiveImage>
            );
        }
        return <img src="/placeholder.png" alt=""/>
    }

    render() {
        return (
            <Link to={`/films/${this.props.id}`}>
                <div 
                    className={`film-item`}
                    ref={elem => this.filmRef = elem}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    onTouchStart={this.onMouseEnter}
                    onTouchEnd={this.onMouseLeave}
                >

                    { this.renderImage() }

                    <div className={`${this.getShowState()} title`}>
                        <div className="content">
                            <h5>{this.props.title}</h5>
                            <span>{secondsToMinutes(this.props.duration)}</span>
                        </div>
                    </div>

                </div>
            </Link>
        );
    }
}

export default FilmItem;
