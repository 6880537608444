import React from 'react';
import { connect } from 'react-redux';
import { createFilm } from '../../../actions';

import FilmForm from './form/FilmForm';

class FilmCreate extends React.Component {
    onSubmit = formValues => {
        this.props.createFilm(formValues);
    }
   
    render() {
        return(
            <div className="film-create background">
                <div className="container">
                    <div className="card-panel hoverable center-align">
                        <div className="card-title">
                            <h3>Crear un nuevo filme</h3>
                        </div>

                        <div className="card-content">
                            <FilmForm onSubmit={this.onSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return { error: state.errors.create_film };
};

export default connect(mapStateToProps, { createFilm })(FilmCreate);
