import React from 'react';

import { connect } from 'react-redux';

import './Admin.css';

import Film from './film/Film';
import AboutForm from './about/AboutForm';

import Modal from '../widgets/Modal';


class Admin extends React.Component {
    renderModal() {
        const { modal } = this.props;
        if(modal) {
            return(
                <Modal 
                    title={modal.title}
                    description={modal.description}
                    onAccept={modal.function}
                />
            );
        }

        return <Modal />;
    }

    render() {
        return(
            <div className="admin container">
                <div className="row admin-title">
                    <div className="col s12 l12">
                        <div className="card-panel hoverable center-align">
                            <h4>Panel de administración</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 l6">
                        <AboutForm />
                    </div>
                    <div className="col s12 l6 admin-film-column">
                        <Film />
                    </div>
                </div>
                
                {this.renderModal()}
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        modal: state.modal
    }
};

export default connect(mapStateToProps)(Admin);
