import { FILL_MODAL } from '../actions/types';


export default (state={}, action) => {
    switch(action.type) {
        case FILL_MODAL:
            return {...action.payload};

        default:
            return state;
    }
};
