import {
    SIGN_IN_ERROR,
    SIGN_OUT_ERROR,
    CREATE_FILM_ERROR,
    GET_FILMS_ERROR,
    GET_FILM_ERROR,
    EDIT_FILM_ERROR,
    DELETE_FILM_ERROR,
    CREATE_ABOUT_ERROR,
    GET_ABOUT_ERROR,
    EDIT_ABOUT_ERROR
} from '../actions/types';


export default (state={}, action) => {
    switch(action.type) {
        case SIGN_IN_ERROR:
            return {...state, sign_in: action.payload};

        case SIGN_OUT_ERROR:
            return {...state, sign_out: action.payload};

        case CREATE_FILM_ERROR:
            return {...state, create_film: action.payload};

        case GET_FILMS_ERROR:
            return {...state, get_films: action.payload};

        case GET_FILM_ERROR:
            return {...state, get_film: action.payload};

        case EDIT_FILM_ERROR:
            return {...state, edit_film: action.payload};

        case DELETE_FILM_ERROR:
            return {...state, delete_film: action.payload};
    
        case CREATE_ABOUT_ERROR:
            return {...state, create_about: action.payload};

        case GET_ABOUT_ERROR:
            return {...state, get_about: action.payload};

        case EDIT_ABOUT_ERROR:
            return {...state, edit_about: action.payload};

        default:
            return state;
    }
}
