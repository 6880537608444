import React from 'react';

import CancelImage from '../../../widgets/CancelImage';
import { fileToImage, reduceImageSize } from '../../../widgets/ImageRatioCrop/imageHandler';


class MultiplePhotoInput extends React.Component {
    state = { images: [] }

    onChange = event => {
        const files = Array.from(event.target.files);

        Promise.all(files.map(async file => {
            const image = await fileToImage(file);
            const resizedFile = await reduceImageSize(image.image, 2048, 2048, file.type, file.name);
            const resizedImage = await fileToImage(resizedFile);

            return resizedImage;
        }))
        .then(images => {
            this.setState(
                state => ({images: state.images.concat(images)}),
                this.setOnChange
            );
        }, error => console.log(error));
    }

    setOnChange() {
        const { onChange } = this.props.input;
        const files = this.state.images.map(image => image.file);
        onChange(files);
    }

    onCancel = key => {
        this.setState(state => {
            let { images } = state;
            images.splice(key, 1);
            return { images };
        }, this.setOnChange);
    }

    renderImages() {
        return this.state.images.map((image, key) => (
            <div key={key} className="col s12 m6 l4">
                <CancelImage onCancel={() => this.onCancel(key)}>
                    <img className="responsive-img" src={image.image} alt=""/>
                </CancelImage>
            </div>
        ));
    }

    render() {
        return (
            <div>
                <div className="center-align col s12">
                    <label className="btn">
                        Agregar
                        <input 
                            type="file"
                            accept="image/*" 
                            multiple
                            onChange={this.onChange}
                            style={{display: 'none'}}
                        />
                    </label>
                </div>
                <div className="photo-images">
                    {this.renderImages()}
                </div>
            </div>
        );
    }
}

export default MultiplePhotoInput;
