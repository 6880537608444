import React from 'react';

import { validClass } from '../../../../util/validClass';


export default ({ input, label, title, meta }) => {
    return (
        <div className="input-field col s12">
            <textarea 
                id={label}
                type="text" {...input}
                className={`${validClass(meta)} materialize-textarea`}>
            </textarea>
            <label htmlFor={label}>{title}</label>
            <span className="helper-text" data-error={meta.error}></span>
        </div>
    );
}
