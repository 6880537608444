import {
    CREATE_FILM,
    EDIT_FILM,
    DELETE_FILM,

    CREATE_FILM_ERROR,
    // GET_FILMS_ERROR,
    // GET_FILM_ERROR,
    EDIT_FILM_ERROR,
    DELETE_FILM_ERROR,
    CREATE_ABOUT_ERROR,
    GET_ABOUT_ERROR,
    EDIT_ABOUT_ERROR
} from '../actions/types';


export default (state={}, action) => {
    let message;
    switch(action.type) {
        case CREATE_FILM:
            message = `El filme "${action.payload.title}" ha sido creado`;
            return { status: 'success', message};
        
        case CREATE_FILM_ERROR:
            if (action.payload) {
                message = action.payload.response.data;
                return { status: 'failure', message};
            }
            return state;
        
        case EDIT_FILM:
            message = `El filme "${action.payload.title}" ha sido modificado`;
            return { status: 'success', message};

        case EDIT_FILM_ERROR:
            if (action.payload) {
                message = action.payload.response.data;
                return { status: 'failure', message};
            }
            return state;

        case DELETE_FILM:
            message = `El filme "${action.payload.title}" ha sido eliminado`;
            return { status: 'success', message};

        case DELETE_FILM_ERROR:
            if (action.payload) {
                message = action.payload.response.data;
                return { status: 'failure', message};
            }
            return state;

        case CREATE_ABOUT_ERROR:
            if (action.payload) {
                message = action.payload.response.data;
                return { status: 'failure', message};
            }
            return state;

        case GET_ABOUT_ERROR:
            if (action.payload) {
                message = action.payload.response.data;
                return { status: 'failure', message};
            }
            return state;

        case EDIT_ABOUT_ERROR:
            if (action.payload) {
                message = action.payload.response.data;
                return { status: 'failure', message};
            }
            return state;

        default:
            return state;
    }
};
