import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { signIn, authenticate } from '../../actions';

import TextInput from '../admin/film/form/TextInput';

import './Login.css';


class LogIn extends React.Component {
    componentDidMount() {
        this.props.authenticate(this.props.location.pathname);
    }

    onSubmit = formValues => {
        const { state } = this.props.location;
        let pathname = state? state.from.pathname: 'admin/';
        this.props.signIn(formValues, pathname);
    }

    renderErrors() {
        let {errors} = this.props;
        if(errors) {
            return (
                <div className="error">
                    {errors}
                </div>
            );
        }
    }

    render() {
        return (
            <div className="login-container">
                <div className="login card-panel hoverable">
                    <h4>Inicio de sesion</h4>
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <Field name="username" component={TextInput} title="Usuario" label="user" />
                        <Field name="password" component={TextInput} title="Contrasena" label="password" />
                        <div className="right-align">
                            <button className="btn">Iniciar</button>
                        </div>
                    </form>
                    {this.renderErrors()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    let { sign_in } = state.errors;
    if(sign_in) {
        return {
            errors: sign_in.response.data.detail
        };
    }

    return {errors: null};
}

export default connect(mapStateToProps, {signIn, authenticate})(reduxForm({ form: 'loginForm', enableReinitialize: true })(LogIn));
