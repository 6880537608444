export class ImageRatioCropModel {
    new = (imageWidth, originalWidth, imageHeight, originalHeight, ratio) => {
        let imageRatio = imageHeight / imageWidth;

        if(imageRatio > ratio) {
            this.width = imageWidth;
            this.height = Math.round(this.width * ratio);

        } else {
            this.height = imageHeight;
            this.width = Math.round(this.height / ratio);
        }

        this.originalWidthRatio = originalWidth / imageWidth;
        this.originalHeightRatio = originalHeight / imageHeight;

        this.ratio = ratio;
        this.maxHypo = Math.round(Math.hypot(this.width, this.height));
        this.hypo = this.maxHypo;
        this.maxWidth = this.width;
        this.maxHeight = this.height;
        this.spaceWidth = imageWidth;
        this.spaceHeight = imageHeight;
        
        this.x = 0;
        this.y = 0;
        this.startX = 0;
        this.startY = 0;
        this.lastX = 0;
        this.lastY = 0;
    }

    startChange = (x, y) => {
        this.startX = x;
        this.startY = y;
        this.lastX = this.x;
        this.lastY = this.y;
        this.startWidth = this.width;
        this.startHeight = this.height;
    }

    finishChange = () => {
        this.startX = 0;
        this.startY = 0;
        this.lastX = 0;
        this.lastY = 0;
        this.hypo = Math.round(Math.hypot(this.width, this.height));
    }

    move = (x, y) => {
        const targetX = this.lastX + x - this.startX;
        const targetY = this.lastY + y - this.startY;

        if(targetX <= 0) {
            this.x = 0;
        } else if(targetX + this.width >= this.spaceWidth) {
            this.x = this.spaceWidth - this.width;
        } else {
            this.x = targetX;
        }

        if(targetY <= 0) {
            this.y = 0;
        } else if(targetY + this.height >= this.spaceHeight) {
            this.y = this.spaceHeight - this.height;
        } else {
            this.y = targetY;
        }
    }

    resize = (x, y) => {
        x = this.startX - x;
        y = this.startY -y;

        let currentHypo = Math.hypot(x, y);
        currentHypo = x < 0 || y < 0? -currentHypo: currentHypo;
        let targetHypo = this.hypo - currentHypo;
        let percentage = 1 - currentHypo / this.hypo;

        // Check if bounderies are beyond
        if(this.x + this.width > this.spaceWidth) {
            this.width = this.spaceWidth - this.x + 1;
            this.height = Math.round(this.width * this.ratio);

        } else if(this.y + this.height > this.spaceHeight) {
            this.height = this.spaceHeight - this.y + 1;
            this.width = Math.round(this.height / this.ratio);

        } else if(targetHypo >= this.maxHypo) {
            // Just able to move from 50% to 100%
            this.width = this.maxWidth;
            this.height = this.maxHeight;

        } else if(targetHypo <= this.maxHypo * 0.5) {
            this.width = this.maxWidth / 2;
            this.height = this.maxHeight / 2;

        } else {
                this.width = Math.round(this.startWidth * percentage);
                this.height = Math.round(this.startHeight * percentage);
        }
    }

    getSquare = () => {
        return {
            x: this.x * this.originalWidthRatio,
            y: this.y * this.originalHeightRatio,
            width: this.width * this.originalWidthRatio,
            height: this.height * this.originalHeightRatio
        };
    }

    getTop = () => {
        return {
            top: 0,
            width: this.spaceWidth,
            height: this.y
        };
    }
    
    getLeft = () => {
        return {
            left: 0,
            top: this.y,
            height: this.height,
            width: this.x
        };
    }

    getBottom = () => {
        return {
            top: this.y + this.height,
            width: this.spaceWidth,
            bottom: 0
        };
    }

    getRight = () => {
        return {
            top: this.y,
            left: this.x + this.width,
            right: 0,
            height: this.height
        };
    }

    getCenter = () => {
        return {
            top: this.y,
            left: this.x,
            width: this.width,
            height: this.height
        };
    }

    getPointer = () => {
        return {
            top: this.y + this.height - 9,
            left: this.x + this.width - 9
        };
    }
}
