import { AUTHENTICATE, SIGN_IN, SIGN_OUT } from '../actions/types';


const INITIAL_STATE = {
    isSignedIn: null
};

export default (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case AUTHENTICATE:
            return {...state, ...action.payload}

        case SIGN_IN:
            return {...state, isSignedIn: true}
        
        case SIGN_OUT:
                return {...state, isSignedIn: false}
        
        default:
            return state
    };
};
