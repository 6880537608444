import React from 'react';

import { Link } from 'react-router-dom';

import './FilmTitle.css';


class FilmTitle extends React.Component {
    render() {
        return(
            <div className="admin-film-title">
                <div className="card-panel hoverable center-align">
                    <h5 className="title">Filmes</h5>
                    <div className="right-align">
                        <Link to="/admin/new">
                            <span className="btn">Nuevo filme</span>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default FilmTitle;
