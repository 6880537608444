import React from 'react';

import './FilmDetail.css';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { getFilm } from '../../actions/index';
import { getFilm as mapStateToProps } from '../../selectors';

import Photos from '../photos/Photos';
import ScrollToTop from '../widgets/ScrollToTop';
import Loader from '../widgets/Loader'


class FilmDetail extends React.Component {
    componentDidMount() {
        this.props.getFilm(this.props.match.params.id);
    }

    renderGallery(photos) {
        if(photos && photos.length) {
            return (
                <div className="gallery">
                    <h4>Galeria</h4>
                    <Photos photos={photos} />
                </div>
            );
        }
    }

    renderVideo(video_id, platform) {
        if(video_id) {
            let url = platform === 'vimeo'? 'player.vimeo.com/video': 'www.youtube.com/embed';

            return(
                <div className="film-wrapper">
                    <iframe 
                        src={`https://${url}/${video_id}`}
                        frameBorder="0" 
                        height="400px"
                        width="800px"
                        allowFullScreen
                        title="video-player"
                    >

                    </iframe>

                    {this.renderVimeoScript()}
                </div>
            );
        }
    }

    renderVimeoScript(platform) {
        if (platform === 'vimeo') {
         return (
            <script src="https://player.vimeo.com/api/player.js"></script>
         );
        }
    }

    renderFilm() {
        const {photos, title, description, video_id, previous_film, next_film, platform} = this.props.film;

        return (
            <div className="film-detail">
                <div className="container">
                    <h3 className="title">{title}</h3>

                    {this.renderVideo(video_id, platform)}

                    <div className="navigation hide-on-med-and-down">
                        <Link className="arrow left" to={`${previous_film}`}>
                            <i className="large material-icons">chevron_left</i>
                        </Link>
                        <Link className="arrow right" to={`${next_film}`}>
                            <i className="large material-icons">chevron_right</i>
                        </Link>
                    </div>

                    <p>
                        <span>Resumen. </span>
                        <br/>
                        {description}
                    </p>

                    {this.renderGallery(photos)}
                </div>

            </div>
        );
    }

    render() {
        if(this.props.film) {
            return (
                <ScrollToTop>
                    {this.renderFilm()}
                </ScrollToTop>
            );
        }

        if(this.props.error) {
            return <div>Error cargando el contenido: {this.props.error.mesagge}</div>;
        }

        return <Loader />;
    }
}

export default connect(mapStateToProps, { getFilm })(FilmDetail);
