import { formValueSelector } from 'redux-form';


export const getFilms = (state) => {
    let films;

    if(state.films) {
        films = Object.entries(state.films).length === 0? []: Object.values(state.films).sort(
            (a, b) => b.id -a.id
        );
    } else {
        films = null;
    }

    return {
        films: films,
        error: state.errors.get_films
    }
};

export const getFilm = (state, ownProps) => {
    let film = state.films? state.films[ownProps.match.params.id]: null;
    film = film && film.video_id? film: null;

    return {
        film,
        error: state.errors.get_film
    };
};

export const getAbout = (state) => {
    const initialValues = state.about? state.about: {};
    // const photo = initialValues.thumbnail? 'original': null;
    const photo = null;
    initialValues.photo = photo;

    const selector = formValueSelector('aboutForm');
    const isNew = initialValues.id? false: true;

    Object.keys(initialValues).forEach(
        key => {
            let value = initialValues[key];
            value = value === null? '': value;
            initialValues[key] = value;
        }
    );

    const aboutFields = Object.keys(initialValues);
    const currentValues = aboutFields.length? selector(state, ...aboutFields): {};

    return {
        isNew,
        initialValues,
        currentValues
    };
};
