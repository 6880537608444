import React from 'react';

import { connect } from 'react-redux';
import { getFilms } from '../../actions';
import { getFilms as mapStateToProps } from '../../selectors';

import './Films.css';

import FilmItem from './FilmItem';
import ScrollToTop from '../widgets/ScrollToTop';
import Loader from '../widgets/Loader';

class Films extends React.Component {
    componentDidMount() {
        this.props.getFilms();
    }

    renderFilms() {
        return this.props.films.map(
            ({id, title, cover, duration}) => 
            <FilmItem 
                key={id}
                id={id}
                title={title}
                duration={duration}
                cover={cover}
            />
        );
    }

    render() {
        if(this.props.films) {
            if(this.props.films.length) {
                return (
                    <ScrollToTop>
                        <div className="films row">
                            {this.renderFilms()}
                        </div>
                    </ScrollToTop>
                );
            }
            return <div>No hay pelis que mostrar, saludos</div>;
        }

        if(this.props.error) {
            return <div>Error cargando contenido: {this.props.error.message}</div>;
        }

        return <Loader />;
    }
}

export default connect(mapStateToProps, { getFilms })(Films);
