import {
    CREATE_FILM,
    GET_FILMS,
    GET_FILM,
    EDIT_FILM,
    DELETE_FILM
} from '../actions/types';

import _ from 'lodash';


export default (state=null, action) => {
    switch(action.type) {
        case CREATE_FILM:
            return {...state, [action.payload.id]: action.payload};
        
        case GET_FILMS:
            return {...state, ..._.mapKeys(action.payload, 'id')};

        case GET_FILM:
            return {...state, [action.payload.id]: action.payload};

        case EDIT_FILM:
            return {...state, [action.payload.id]: action.payload};

        case DELETE_FILM:
            return _.omit(state, action.payload.id);

        default:
            return state;
    }
};
