export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_ERROR = 'AUTHENTICATE_ERROR';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';

export const CREATE_FILM = 'CREATE_FILM';
export const CREATE_FILM_ERROR = 'CREATE_FILM_ERROR';

export const GET_FILMS = 'GET_FILMS';
export const GET_FILMS_ERROR = 'GET_FILMS_ERROR';

export const GET_FILM = 'GET_FILM';
export const GET_FILM_ERROR = 'GET_FILM_ERROR';

export const EDIT_FILM = 'EDIT_FILM';
export const EDIT_FILM_ERROR = 'EDIT_FILM_ERROR';

export const DELETE_FILM = 'DELETE_FILM';
export const DELETE_FILM_ERROR = 'DELETE_FILM_ERROR';

export const CREATE_ABOUT = 'CREATE_ABOUT';
export const CREATE_ABOUT_ERROR = 'CREATE_ABOUT_ERROR';

export const GET_ABOUT = 'GET_ABOUT';
export const GET_ABOUT_ERROR = 'GET_ABOUT_ERROR';

export const EDIT_ABOUT = 'EDIT_ABOUT';
export const EDIT_ABOUT_ERROR = 'EDIT_ABOUT_ERROR';

export const FILL_MODAL = 'FILL_MODAL';
export const FILM_UPDATING = 'FILM_UPDATING';