import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import about from './about';
import films from './films';
import auth from './auth';
import errors from './errors';
import modal from './modal';
import notification from './notification';
import filmUpdating from './filmUpdating';


export default combineReducers({
    auth, about, errors, films, form, modal, notification, filmUpdating
});
