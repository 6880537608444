import React from 'react';

import Photo from './Photo';


class Photos extends React.Component {
    renderPhotos() {
        return this.props.photos.map(
            photo => <Photo key={photo.id} photo={photo}/>
        );
    }

    render() {
        return (
            <div className="row">
                {this.renderPhotos()}
            </div>    
        );
    }

}

export default Photos;
