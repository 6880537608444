import React from 'react';

import { connect } from 'react-redux';
import { getAbout } from '../actions';

import './About.css';

import ProgressiveImage from 'react-progressive-image';
import Loader from './widgets/Loader'


class About extends React.Component {
    state = {selectedContact: ''}

    componentDidMount() {
        this.props.getAbout();
    }

    getContactClass(contact) {
        return this.state.selectedContact === contact? 'active': 'inactive'
    }

    removeActiveClass(event, contact) {
        const { selectedContact } = this.state;
        if(selectedContact === contact) {
            event.stopPropagation();
            this.setState({selectedContact: ''});
        }
    }

    renderPhoto() {
        const { thumbnail, small, medium, large, xlarge } = this.props.about;
        if (thumbnail) {
            return (
                <ProgressiveImage 
                    delay={500}
                    src={small} 
                    srcSetData={{
                        srcSet: `${small}, ${medium} 1.3x, ${large} 1.9x, ${xlarge} 2.7x`
                    }}
                    placeholder={thumbnail}
                >
                    {(src, loading, srcSetData) => (
                        <img 
                            style={{ filter: loading ? 'blur(10px)' : 'blur(0)' }}
                            src={src}
                            srcSet={srcSetData.srcSet}
                            alt="profile"
                            className="responsive-img"
                        />
                    )}
                </ProgressiveImage>
            );
        }

        return <img src="/profile.jpeg" className="responsive-img" alt=""/>;
    }

    renderDescription() {
        const {  description } = this.props.about;
        if (description) {
            return (
                <div className="description">
                    <h4>Acerca</h4>
                    <p> { description } </p>
                </div>
            );
        }
    }

    renderAbout() {
        const {  description, phone, email, fb, youtube, instagram } = this.props.about;
        return (
            <div className="container">
                <div className="about">
                    <div className="profile">
                        <div className="photo">
                            { this.renderPhoto() }
                        </div>
                        <div className="contact">
                            <div
                                className="contact-icon-containter"
                                onClick={() => this.setState({selectedContact: 'phone'})}
                                onBlur={() => this.setState({selectedContact: ''})}
                                tabIndex={0}
                            >
                                <i
                                    onClick={(event) => this.removeActiveClass(event, 'phone')}
                                    className={`fas fa-mobile-alt fa-2x icon-${this.getContactClass('phone')}`}
                                ></i>
                                <span className={this.getContactClass('phone')}>
                                    { phone }
                                </span>
                            </div>
                            <div
                                className="contact-icon-containter"
                                onClick={() => this.setState({selectedContact: 'mail'})}
                                onBlur={() => this.setState({selectedContact: ''})}
                                tabIndex={0}
                            >
                                <i
                                    onClick={(event) => this.removeActiveClass(event, 'mail')}
                                    className={`fas fa-envelope fa-2x icon-${this.getContactClass('mail')}`}
                                ></i>
                                <span className={this.getContactClass('mail')}>
                                    { email }
                                </span>
                            </div>
                            <a target="_blank" rel="noopener noreferrer" href={ fb }>
                                <i className="fab fa-facebook fa-2x"></i>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href={ instagram }>
                                <i className="fab fa-instagram fa-2x"></i>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href={ youtube }>
                                <i className="fab fa-youtube fa-2x"></i>
                            </a>                        
                        </div>
                    </div> 
                    { this.renderDescription() }
                </div>
            </div>
        );
    }

    render() {
        if(this.props.about) {
            if(this.props.about.thumbnail || this.props.about.description) {
                return this.renderAbout(); 
            }
            return <div>Vacio</div>;
        }
        if(this.props.error) {
            return <div>Error cargando el contenido</div>;
        }
        return <Loader />;
    }
}

const mapStateToProps = (state) => {
    return {
        about: state.about,
        error: state.errors.get_about
    }
};

export default connect(mapStateToProps, { getAbout })(About);
