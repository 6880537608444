import React from 'react';


class AboutTextArea extends React.Component {
    render() {
        const { input, meta } = this.props;
        return (
            <React.Fragment>
                <textarea 
                    {...input} 
                    autoComplete="off"
                    className="materialize-textarea"
                    placeholder="Haz click para agregar"
                ></textarea>
                <span className="helper-text" data-error={meta.error}></span>
            </React.Fragment>
        );
    }
}

export default AboutTextArea;
