import React from 'react';

import './CancelImage.css';


class CancelImage extends React.Component {
    render() {
        return (
            <div className="cancel-image">
                {this.props.children}
                <i 
                    className="material-icons"
                    onClick={this.props.onCancel}
                >
                    close
                </i>
            </div>
        );
    }
}

export default CancelImage;
