export const authHeader = () => {
    let user = JSON.parse(localStorage.getItem('user'));

    if(user && user.access) {
        return {
            headers: {Authorization: 'Bearer ' + user.access}
        };
    }

    return {};
};
