import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { editFilm, getFilm } from '../../../actions';
import { getFilm as mapStateToProps } from '../../../selectors';

import FilmForm from './form/FilmForm';
import Loader from '../../widgets/Loader';


class FilmUpdate extends React.Component {
    componentDidMount() {
        this.props.getFilm(this.props.match.params.id);
    }

    onSubmit = formValues => {
        this.props.editFilm(formValues, this.props.match.params.id);
    }

    renderForm() {
        const { film } = this.props;
        return (
            <div className="film-create background">
                <div className="container">
                    <div className="card-panel hoverable center-align">
                        <div className="card-title">
                            <h3>Editar {film.title}</h3>
                        </div>

                        <div className="card-content">
                            <FilmForm
                                onSubmit={this.onSubmit}
                                initialValues={_.pick(film, 'title', 'video_id', 'description', 'platform')}
                                update={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { film, error } = this.props;
        if(film) {
            return this.renderForm();
        }

        if (error) {
            return <div>Errores: </div>
        }

        return <Loader />
    }
}

export default connect(mapStateToProps, { getFilm, editFilm })(FilmUpdate);
