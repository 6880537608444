import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import './FilmForm.css'

import TextInput from './TextInput';
import TextArea from './TextArea';
import PhotoInput from './PhotoInput';
import MultiplePhotoInput from './MultiplePhotoInput';
import { startFilmUpdate } from '../../../../actions';

import M from 'materialize-css';

class FilmForm extends React.Component {
    componentDidUpdate() {
        M.updateTextFields();
    }

    onSubmit = (formValues) => {
        this.props.startFilmUpdate();
        const { 
            title, video_id, description, cover_image, photo_images, platform
        } = formValues;

        const data = new FormData();

        data.append('title', title);
        data.append('video_id', video_id);
        data.append('description', description);
        data.append('platform', platform);

        if (!this.props.update) {
            data.append('cover_image', cover_image);
    
            if(photo_images) {
                for(let i = 0; i < photo_images.length; i++) {
                    data.append('photo_images', photo_images[i]);
                }
            }
        }
        this.props.onSubmit(data);
    }

    render() {
        return (
            <div>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="row">
                    <Field name="title" component={TextInput} title="Titulo" label="title" />
                    <Field name="video_id" component={TextInput} title="Id de Video" label="video" />
                </div>
                <div className="row">
                    <Field name="description" component={TextArea} title="Descripción" label="description" />
                </div>
                <div className="row">
                    <div className="input-field col s6">
                        <fieldset>
                        <legend>Plataforma</legend>
                            <p>
                                <label>
                                    <Field name="platform" component="input" type="radio" value="youtube" /> 
                                    <span>Youtube</span>
                                </label>
                            </p>
                            <p>
                                <label>
                                    <Field name="platform" component="input" type="radio" value="vimeo" />
                                    <span>Vimeo</span>
                                </label>
                            </p>
                        </fieldset>
                    </div>
                </div>
                <div className="row cover">
                    <h4>Portada</h4>
                    <Field name="cover_image" component={PhotoInput} title="Portada"/>
                </div>
                <div className="row photos">
                    <h4>Fotos</h4>
                    <Field name="photo_images" component={MultiplePhotoInput} title="Fotos"/>
                </div>
                <div className="row right-align">
                    <button className="btn-floating btn-large" disabled={this.props.errors || this.props.filmUpdating}>
                        <i className="material-icons">add</i>
                    </button>
                </div>
            </form>
            </div>
        );
    }
}

const validate = (formValues, props) => {
    const errors = {};
    if(!formValues.title) {
        errors.title = 'Debes agregar un título';
    }

    if(!formValues.video_id) {
        errors.video_id = 'Debes agregar un id de video';
    }

    if(!formValues.description) {
        errors.description = 'Debes agregar una descripción';
    }

    if(!formValues.platform) {
        errors.platform = 'Debes agregar una platform';
    }

    if(!formValues.cover_image && !props.update) {
        errors.cover_image = 'Debes agregar una imagen de portada';
    }

    return errors;
}

const mapStateToProps = state => {
    const { filmForm } = state.form;
    const { filmUpdating } = state;

    if(filmForm) {
        let errors = filmForm.syncErrors? true: false;
        return { errors, filmUpdating };
    }

    return { errors: false, filmUpdating };
};

export default connect(mapStateToProps, { startFilmUpdate })(reduxForm({
     form: 'filmForm', enableReinitialize: true, validate
})(FilmForm));
