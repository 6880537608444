import React from 'react';

import './ImageRatioCrop.css';
import { ImageRatioCropModel } from './ImageRatioCropModel';
import { imageToFile } from './imageHandler';


export class ImageRatioCrop extends React.Component {
    state = {
        isDragging: false,
        top: {},
        left: {},
        bottom: {},
        right: {},
        center: {},
        pointer: {}
    };

    componentDidMount() {
        this.model = new ImageRatioCropModel();
        this.props.onAccept(this.onAccept);

        this.imageRef.addEventListener(
            'load', () => {
                let { offsetWidth, naturalWidth, offsetHeight, naturalHeight } = this.imageRef;
                let { sizerRatio } = this.props;
                this.model.new(offsetWidth, naturalWidth, offsetHeight, naturalHeight, sizerRatio);
                this.updateSizerContainers();
            }
        );
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.mouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
    }

    updateSizerContainers() {
        this.setState({
            top: this.model.getTop(),
            left: this.model.getLeft(),
            bottom: this.model.getBottom(),
            right: this.model.getRight(),
            center: this.model.getCenter(),
            pointer: this.model.getPointer()
        });
    }

    onAccept = () => {
        const { imageType, imageName } = this.props;
        let {x, y, width, height} = this.model.getSquare();
        return imageToFile(this.imageRef, imageType, imageName, x, y, width, height);
    }

    onMouseDown = (event, type) => {
        this.mouseMove = (event) => this.onMouseMove(event, type);  
        const {clientX, clientY} = event;
        window.addEventListener('mousemove', this.mouseMove);
        window.addEventListener('mouseup', this.onMouseUp);
        
        this.model.startChange(clientX, clientY);
        this.setState({isDragging: true});
        event.preventDefault();
    }

    onMouseMove = ({clientX, clientY}, type) => {
        if(!this.state.isDragging) {
            return
        }

        if(type === 'center') {
            this.model.move(clientX, clientY);
        } else {
            this.model.resize(clientX, clientY);
        }

        this.updateSizerContainers();
    }

    onMouseUp = () => {
        window.removeEventListener('mousemove', this.mouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);

        this.model.finishChange();
        this.setState({ isDragging: false });

    }

    render() {
        if(this.model) {
            return (
                <div className="image-ratio-crop">
                    {React.cloneElement(this.props.children, {ref: elem => this.imageRef = elem})}
                    <div className="top" style={this.state.top}></div>
                    <div className="left" style={this.state.left}></div>
                    <div className="bottom" style={this.state.bottom}></div>
                    <div className="right" style={this.state.right}></div>
                    <div 
                        className="center"
                        onMouseDown={event => this.onMouseDown(event, 'center')}
                        style={this.state.center}
                    >
                    </div>
                    <div 
                        className="circle-pointer"
                        onMouseDown={event => this.onMouseDown(event, 'pointer')}
                        style={this.state.pointer}
                    >
                    </div>
                </div>
            );
        }
        return (
            <div>
                {React.cloneElement(this.props.children, {ref: elem => this.imageRef = elem})}
            </div>
        );
    }
}
