import React from 'react';

import {Route, Switch, useRouteMatch } from 'react-router-dom';

import NavBar from './NavBar'; 
import Films from './films/Films';
import FilmDetail from './films/FilmDetail';


const FilmLayout = () => {
    const { path } = useRouteMatch();

    const home = { path: "/films", name: "Eliezer Espinosa" };
    const links = [
        { path: "/films", name: "Filmes" },
        { path: "/about", name: "Acerca" }
    ];

    return (
        <div>
            <header>
                <NavBar home={home} links={links} />
            </header>

            <main>
                <Switch>
                    <Route exact path={path} component={Films}/>
                    <Route path={`${path}/:id`} render={
                        props => <FilmDetail key={props.match.params.id} {...props} />
                    } />
                </Switch>
            </main>

            <footer></footer>
        </div>
    );
};

export default FilmLayout;
