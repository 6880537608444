import {
    FILM_UPDATING
} from '../actions/types';


export default (state=false, action) => {
    switch(action.type) {
        case FILM_UPDATING:
            return action.payload;

        default:
            return state;
    }
};
