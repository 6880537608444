import React from 'react';

import M from 'materialize-css';


class Modal extends React.Component {
    componentDidMount() {
        M.Modal.init(this.modal);
    }

    render() {
        const {title, description, onAccept} = this.props;
        return (
            <div ref={elem => this.modal = elem} id="modal" className="modal text-center">
                <div className="modal-content">
                    <h4>{title}</h4>
                    <p>{description}</p>
                </div>

                <div className="modal-footer">
                    <button className="modal-close btn-flat">Cancelar</button>
                    <button className="modal-action modal-close btn" onClick={onAccept}>Aceptar</button>
                </div>
            </div>
        );
    }
}

export default Modal;
