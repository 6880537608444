import jwt_decode from 'jwt-decode';


export const getRefreshToken = () => {
    let token = localStorage.getItem('user');
    token = JSON.parse(token);

    return token.refresh;
};


export const accessValid = () => {
    return _tokenValid('access');
};


export const refreshValid = () => {
    return _tokenValid('refresh');
};


const _tokenValid = (type) => {
    let tokenExpire = _getTokenExpire(type);
    if(tokenExpire) {
        let now = Date.now() / 1000;

        return tokenExpire > now;
    }
};

const _getTokenExpire = type => {
    let token = _getToken(type);

    if(token) {
        return token.exp;
    }

    return null;
};

const _getToken = type => {
    let token = localStorage.getItem('user');
    token = JSON.parse(token);

    try {
        return jwt_decode(token[type]);

    } catch(error) {
        return null;
    }
};