import React from 'react';

import NavBar from './NavBar'; 
import About from './About';


const AboutLayout = () => {
    const home = { path: "/films", name: "Eliezer Espinosa" };
    const links = [
        { path: "/films", name: "Filmes" },
        { path: "/about", name: "Acerca" }
    ];

    return (
        <div>
            <header>
                <NavBar home={home} links={links} />
            </header>
            <main>
                <About />
            </main>
            <footer></footer>
        </div>
    );
};

export default AboutLayout;
