import React from 'react';
import { connect } from 'react-redux';

import { ImageRatioCrop } from '../../widgets/ImageRatioCrop';
import CancelImage from '../../widgets/CancelImage';
import { fileToImage, reduceImageSize } from '../../widgets/ImageRatioCrop/imageHandler';
import ProgressiveImage from 'react-progressive-image';

import './PhotoInput.css';


class PhotoInput extends React.Component {
    state = { image: null, selected: false, accepted: false, originalRejected: false };

    onAccept = async () => {
        try {
            const file = await this.ResizedImage();
            const image = await fileToImage(file);
            const resizedFile = await reduceImageSize(image.image, 2048, 2048, file.type, file.name);
            const resizedImage = await fileToImage(resizedFile);
            this.setState({ image: resizedImage, accepted: true }, this.setOnChange);

        } catch(error) {
            console.log(error)
        }
    }

    onCancel = () => {
        this.setState(
            { image: null, selected: false, accepted: false },
            this.setOnChange
        );
    }

    onOriginalCancel = () => {
        this.setState(
            { image: null, selected: false, accepted: false, originalRejected: true },
            this.setOnChange
        );
    }

    onChange = (event) => {
        const file = event.target.files[0];
        const image = fileToImage(file);
        image.then(
            image => this.setState({ image, selected: true }),
            error => console.log(error)
        );
    }

    setOnChange() {
        const { onChange } = this.props.input;
        const { image } = this.state;
        const file = image? image.file: null;
        onChange(file);
    }

    renderAcceptedImage() {
        const { image } = this.state;
        return (
            <CancelImage onCancel={this.onCancel}>
                <img className="responsive-img" src={image.image} alt=""/>
            </CancelImage>
        );
    }

    renderOriginalImage() {
        const { thumbnail, small, medium, large, xlarge } = this.props;

        return (
            <CancelImage onCancel={this.onOriginalCancel}>
                <ProgressiveImage 
                    delay={500}
                    src={small} 
                    srcSetData={{
                        srcSet: `${small}, ${medium} 1.3x, ${large} 1.9x, ${xlarge} 2.7x`
                    }}
                    placeholder={thumbnail}
                >
                    {(src, loading, srcSetData) => (
                        <img 
                            src={src}
                            srcSet={srcSetData.srcSet}
                            alt="profile"
                            className="responsive-img"
                        />
                    )}
                </ProgressiveImage>
            </CancelImage>
        );
    }

    renderSelectedImage() {
        const { image } = this.state;
        if(!image) return;

        return (
            <div className="selected-container">
                <ImageRatioCrop
                    sizerRatio={1}
                    onAccept={e=> this.ResizedImage = e}
                    imageType={image.file.type}
                    imageName={image.file.name}
                >
                    <img className="responsive-img" src={ image.image } alt=""/>
                </ImageRatioCrop>
                <div className="selected-controls right-align">
                    <button className="btn" type="button" onClick={this.onAccept}>Accept</button>
                    <button className="btn-flat" type="button" onClick={this.onCancel}>Cancel</button>
                </div>
            </div>
        );
    }

    renderPlaceholder() {
        return (
            <div className="placeholder">
            <label>
                <input 
                    type="file"
                    accept="image/*" 
                    onChange={this.onChange}
                    style={{display: 'none'}}
                />
                <img className="responsive-img" src="/profile.jpeg" alt=""/>
            </label>
                <i className="material-icons">add</i>
            </div>
        );
    }

    render() {
        if(this.props.thumbnail && !this.state.originalRejected) {
            return this.renderOriginalImage()
        }

        if(this.state.accepted) return this.renderAcceptedImage();

        if(this.state.selected) return this.renderSelectedImage();

        return (
            <div>
                { this.renderPlaceholder() }
            </div>
        );
    }
}

export default connect()(PhotoInput);
