import {
    CREATE_ABOUT,
    GET_ABOUT,
    EDIT_ABOUT
} from '../actions/types';


export default (state=null, action) => {
    switch(action.type) {
        case CREATE_ABOUT:
            return {...action.payload};

        case GET_ABOUT:
            return {...action.payload};

        case EDIT_ABOUT:
            return {...action.payload};

        default:
            return state;
    };
};
