import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fillModal, deleteFilm } from '../../../actions';

import './FilmItem.css';


class FilmItem extends React.Component {
    state = {isActive: false};

    onActivate = () => {
        this.setState({isActive: true});
    }

    onDeactivate = () => {
        this.setState({isActive: false});
    }

    setModal = () => {
        const { film, deleteFilm } = this.props;
        return {
            title: 'Borrar Filme',
            description: `Estas seguro de borrar "${film.title}"?`,
            function: () => deleteFilm(film.id)
        };
    }

    render() {
        const hoveredStatus = this.state.isActive? 'is-hovered': 'is-not-hovered';
        const { film } = this.props;
        const image = film.cover? film.cover.thumbnail: '/placeholder.png';

        return(
            <div 
                className={`admin-film-item ${hoveredStatus}`}
                onMouseEnter={this.onActivate}
                onMouseLeave={this.onDeactivate}
                onTouchStart={this.onActivate}
                onTouchEnd={this.onDeactivate}
            >
                <Link to={`/admin/${film.id}/edit`}>
                    <li className="collection-item avatar">
                        <img src={image} alt={film.title} className="circle"/>
                        <span className="title">{film.title}</span>
                    </li>
                </Link>
                <button 
                    className="btn-floating btn-small modal-trigger"
                    onClick={() => this.props.fillModal(this.setModal())}
                    data-target="modal"
                >
                    <i className="material-icons tiny">delete</i>
                </button>
                <hr/>
            </div>
        );
    }
}

export default connect(null, { fillModal, deleteFilm })(FilmItem);
