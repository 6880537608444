export const fileToImage = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', e => {
            resolve({
                file,
                image: e.target.result
            });
        });
        reader.addEventListener('error', reject);
        reader.readAsDataURL(file);
    });
};


export const imageToFile = (image, type, name, x, y, width, height) => {
    return (new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(
            image, x, y, width, height, 0, 0, width, height
        );

        canvas.toBlob(
            blob => {
                let file = new File([blob], name, { type: type });
                return resolve(file);
            }, 
            name
        );
    }));
};


export const reduceImageSize = (imageURL, maxWidth, maxHeight, type, name) => {
    return new Promise((resolve, reject) => {
        const image = document.createElement('img');
        image.addEventListener('load', () => {
            let width = image.width;
            let height = image.height;

            if(width > height) {
                if(width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if(height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);

            canvas.toBlob(
                blob => {
                    let file = new File([blob], name, { type: type });
                    return resolve(file);
                }, "image/jpeg", 0.7
            );
        });

        image.addEventListener('error', reject);
        image.src = imageURL;
    });
};
